<template>
  <section class="tables">
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="card-title">Tous les formations</div>
            <nav aria-label="breadcrumb" class="float-right">
              <ol class="breadcrumb">
                <router-link to="addformation">
                  <li class="breadcrumb-item btn btn-primary">
                    Ajouter une formation
                  </li>
                </router-link>
              </ol>
            </nav>

            <div class="table-responsive">
              <input
                value=""
                class="form-control search-input"
                placeholder="Recherche par theme"
                type="text"
                v-model="search"
              />
              <table class="table">
                <thead>
                  <tr>
                    <th>Nom</th>
                    <th>Adresse Formateur</th>
                    <th>Membre</th>
                    <th>Contenu</th>
                    <th>date Debut</th>
                    <th>date Fin</th>

                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(formation, index) in searchFunction" :key="index">
                    <td>{{ formation.theme }}</td>
                    <td>{{ formation.adresse }}</td>
                     <td>{{ formation.membre }}</td>
                     <td>
                      <a  v-bind:href="'../../../../front-siry/src/documents/'+ formation.plan" target="_blank">{{ formation.plan }}</a>
                     </td> 
                    <td>{{ formation.date_debut  | formatDate}}</td>
                    <td>{{ formation.date_fin  | formatDate}}</td>

                    <td>
                     <router-link
                        :to="{
                          name:'updateformation',
                          params:{id:formation._id },
                        }"
                        > 
                        <i
                          class="mdi mdi-pen icone text-success"
                          style="color: green"
                        ></i>
                      </router-link>
                      
                       
                      <i
                        class="mdi mdi-delete icone text-danger"
                        title="Supprimer le produit"
                        @click="deleteformation(formation._id)"
                      ></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { HTTP } from "@/axios";
import Swal from "sweetalert2";
import Toast from "sweetalert2";
export default {
  data() {
    return {
      formations: [],
      search:"",
    };
  },

  computed: {
    searchFunction() {
      return this.formations.filter((item) => {
        return item.theme.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
      });
    },
  },

  created() {
    this.getformations();
  },
  

  methods: {
    getformations() {
      
    HTTP.get("formations/getformations").then(
        (res) => {
          this.formations = res.data;
          console.log(this.formations);
        },
        (err) => {
          this.error = err.response.data.error;
          console.log(this.error);

        }
      );
    },
    deleteformation(id) {
      Swal.fire({
        title: "Êtes-vous sûrs ?",
        text: "Vous voulez supprimé la formation",
        icon: "warning",
        cancelButtonText: "Annuler",
        showCancelButton: true,
        confirmButtonColor: "#3e884f",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui, valider!",
      }).then((result) => {
        if (result.isConfirmed) {
          HTTP
            .delete("formations/deleteformation/" + id)
            .then((response) => {
              //this.$htmlToPaper("printNow");

              Toast.fire({
                position: "top-center",
                icon: "success",
                title: "Formation supprimé",
              });

              this.getformations();
            });
        }
      });
    },
  },
};
</script>

